/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from "react"
import dynamic from "next/dynamic"
import { uid } from "uid"
import Slider from "react-slick"

import { useGlobalContext } from "../../context/layout-context"

const PrevButton = dynamic(() => import("../buttons/button-triangle"), {
  ssr: false,
})
const NextButton = dynamic(() => import("../buttons/next-button"), {
  ssr: false,
})
const ImageContentful = dynamic(() => import("../contentful/image"), {
  ssr: false,
})
const ButtonPlayVideo = dynamic(() => import("../buttons/button-play-video"), {
  ssr: false,
})

function SliderComponentLargeImages({
  imageItems,
  textClasslist,
  wrapperClassList,
  infiniteLoop,
}) {
  const { globalState } = useGlobalContext()
  const { isModalOpen } = globalState || {}

  const sliderRef = useRef()
  const stickyBar = useRef()

  function NextArrow() {
    return (
      <NextButton
        classList="flex items-center justify-end w-auto z-1 absolute right-2.5 inset-y-0"
        onClick={() => sliderRef.current.slickNext()}
      />
    )
  }

  function PrevArrow() {
    return (
      <PrevButton
        classList="flex items-center justify-start w-auto z-1 absolute left-2.5 inset-y-0"
        onClick={() => sliderRef.current.slickPrev()}
      />
    )
  }

  const settings = {
    dots: false,
    infinite: !!infiniteLoop,
    speed: 500,
    draggable: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: false,
    variableWidth: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <React.StrictMode>
      {imageItems ? (
        <div
          className={`overflow-x-hidden relative py-0 z-0 block w-full inset-x-0 bg-white bg-opacity-90 transform ${
            isModalOpen ? "-translate-y-1000px" : "translate-y-0"
          } ${wrapperClassList}`}
          ref={stickyBar}
          data-layer-component="slider-component-large-images"
        >
          <div>
            <div className="flex items-stretch justify-between flex-wrap overflow-hidden">
              <div className="w-full flex items-center justify-between relative">
                <Slider
                  {...settings}
                  ref={sliderRef}
                  className="w-full relative"
                >
                  {imageItems.map(image => (
                    <div key={uid(16)}>
                      <div className="flex-col md:flex-row flex justify-between items-center">
                        <div className="mb-0 relative pb-80 w-full">
                          <ImageContentful
                            sizes="70vw"
                            src={image.url}
                            width={image.width}
                            height={image.height}
                            title={image.captionAlt || image.title || image.fileName}
                            layout="fill"
                            classList="object-cover object-center"
                          />
                          {image.videoId && image.source ? (
                            <div className="absolute inset-0 flex items-center justify-center z-0">
                              <ButtonPlayVideo
                                id={image.videoId}
                                source={image.source}
                                buttonClassList="w-20 h-20 text-white"
                              />
                            </div>
                          ) : null}
                          <div className={textClasslist}>
                            {image.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.StrictMode>
  )
}

export default SliderComponentLargeImages
